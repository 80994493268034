import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dateStart", "dateEnd"]

  connect() {
    this.initialdatePicker(this.dateStartTarget, this.targetOptions(this.dateStartTarget), false)

    if (this.hasDateEndTarget) {
      this.initialdatePicker(this.dateEndTarget, this.targetOptions(this.dateEndTarget), true)
    }
  }

  initialdatePicker(target, options, isDateEnd) {
    $(target).datepicker(options).on("changeDate", (event) => {
      this.element.dispatchEvent(new Event("change"))
      if (this.hasDateEndTarget && event.target.dataset.datepickerTarget == "dateStart") {
        $(this.dateEndTarget).datepicker('setStartDate', event.date);
      }
    })

    if (isDateEnd) {
      // add background color to the selected date for create date range
      $(target).datepicker().on("show", () => {
        $(".datepicker-days td").on("mouseenter", (event) => {
          $(event.target).parent().addClass('finalRow')
          $('.finalRow').prevAll().find('td:not(.disabled)').addClass('highlight')
          $(event.target).prevAll('td:not(.disabled)').addClass('highlight')
        })

        $(".datepicker-days td").on("mouseleave", (event) => {
          $(event.target).parent().removeClass('finalRow')
          $('.datepicker-days td').removeClass('highlight')
        })
      })
    }
  }

  targetOptions(target) {
    return {
      autoclose: true,
      language: $('html').attr('lang') || 'fr',
      format: target.dataset.datepickerFormat || "dd/mm/yyyy",
      orientation: target.dataset.datepickerOrientation || "bottom",
      startDate: target.dataset.datepickerStartDate == "false" ? null : (target.dataset.datepickerStartDate || new Date()),
      datesDisabled: target.dataset.datepickerDisableDates || []
    }
  }
}
