import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["slide"]

  initialize() {
    this.index = 0
    this.lastSlideIndex = this.slideTargets.length - 1

    this.preloadNext()
    this.preloadPrev()
  }

  next() {
    this.index++
    this.resetIndex()
    this.showCurrentSlide()
    this.preloadNext()
  }

  prev() {
    this.index--
    this.resetIndex()
    this.showCurrentSlide()
    this.preloadPrev()
  }

  showCurrentSlide() {
    this.slideTargets.forEach((element, index) => {
      element.hidden = index !== Number(this.index)
    })
  }

  resetIndex(){
    if(this.index < 0) {
      this.index = this.lastSlideIndex
    }

    if(this.index > this.lastSlideIndex) {
      this.index = 0
    }
  }

  preloadNext(){
    const preloadIndex = this.index === this.lastSlideIndex ?  0 : this.index + 1
    this.appendImage(preloadIndex)
  }

  preloadPrev(){
    const preloadIndex = this.index === 0 ? this.lastSlideIndex : this.index - 1
    this.appendImage(preloadIndex)
  }

  appendImage(preloadIndex){
    let picture = this.slideTargets[preloadIndex].querySelector('picture')
    let fakeImage = picture.querySelector('.fake-image')

    if (fakeImage) {
      const img = document.createElement('img');
      img.src = fakeImage.dataset.src
      img.itemprop = fakeImage.dataset.src
      img.alt = fakeImage.dataset.alt

      picture.appendChild(img)
      fakeImage.remove()
    }
  }

}
