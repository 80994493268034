import { Controller } from "@hotwired/stimulus"
import { useIntersection } from 'stimulus-use'
import { isMobile } from "../helpers/platform_helpers"
import { delay } from "../helpers/timing_helpers"

const RESIZE_DELAY = 300

export default class extends Controller {
  static targets = ["item", "itemsContainer", "control", "controlPrev", "controlNext"]

  connect() {
    useIntersection(this)
  }

  appear() {
    if (!this.currentIndex) {
      this.setup()
    }
  }

  // PUBLIC API

  next() {
    if (this.currentIndex === this.numberOfItems - 1) { return }

    this.currentIndex++
    if (this.offset > this.stopLength) {
      this.translate(-this.stopLength)
      this.currentIndex = this.numberOfItems - this.maximumItemsVisible
    } else {
      this.translate(-this.offset)
    }
    this.setControls()
  }

  prev() {
    if (this.currentIndex === 0) { return }

    this.currentIndex--
    this.translate(-this.offset)
    this.setControls()
  }

  // Resize is handled every RESIZE_DELAY to avoid costly computations
  // The carousel is reset to zero
  async handleWindowResize() {
    if (!this.resizeScheduled) {
      this.resizeScheduled = true
      await delay(RESIZE_DELAY)
      this.resizeScheduled = false
      this.setup()
    }
  }

  // GETTERS

  get numberOfItems() {
    return this.itemTargets.length
  }

  // Amount of pixels by which we want to translate the carousel to
  // show the item at index this.currentIndex.
  get offset() {
    return this.currentIndex * (this.itemWidth + this.margin)
  }

  // Maximum length the carousel is able to translate
  get stopLength() {
    return this.itemsContainerWidth - (this.carouselWidth + this.margin)
  }

  // Maximum number of full visible items the carousel can show
  get maximumItemsVisible() {
    return Math.trunc(this.carouselWidth / (this.itemWidth + this.margin))
  }

  // True if all the items from the carousel are visible in the viewport
  // without the need for scrolling
  get allItemsVisible() {
    return this.numberOfItems <= this.maximumItemsVisible
  }

  // PRIVATE

  setup() {
    this.currentIndex = 0
    this.translate(0)
    this.margin = this.data.has("margin") ? Number.parseInt(this.data.get("margin")) : 10
    this.setMargin()
    this.setElementsWidth()
    this.setControls()

    if (isMobile) {
      this.element.classList.add("carousel--mobile")
    }
  }

  setControls() {
    if (isMobile || this.allItemsVisible) {
      this.hideControls()
    } else {
      this.showControls()
    }

    if (this.currentIndex === 0) {
      this.controlPrevTarget.disabled = true
    } else {
      this.controlPrevTarget.disabled = false
    }
    if (this.currentIndex === (this.numberOfItems - this.maximumItemsVisible)) {
      this.controlNextTarget.disabled = true
    } else {
      this.controlNextTarget.disabled = false
    }
  }

  setMargin() {
    Array.from(this.itemsContainerTarget.children).forEach(el => el.style.marginRight = this.margin + "px")
  }

  setElementsWidth() {
    this.carouselWidth       = this.element.offsetWidth
    this.itemWidth           = this.itemTarget.offsetWidth
    this.itemsContainerWidth = this.numberOfItems * (this.itemWidth + this.margin)
  }

  translate(value) {
    this.itemsContainerTarget.style.transform = `translate(${value}px, 0)`
  }

  hideControls() {
    this.controlTargets.forEach(control => control.classList.add("d-none"))
  }

  showControls() {
    this.controlTargets.forEach(control => control.classList.remove("d-none"))
  }
}
