import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  connect() {
    this.load()
  }

  async load() {
    const response = await get(this.data.get("url"))
    if (response.ok) {
      this.element.innerHTML = await response.text
    }
  }
}
