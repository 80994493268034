import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { name: String }

  connect() {
    if (Cookies.get(this.nameValue)) {
      this.hide()
    } else {
      this.show()
    }
  }

  dismiss(event) {
    event.preventDefault()
    Cookies.set(this.nameValue, true)
    this.hide()
  }

  show() {
    $(this.element).removeClass("d-none")
    $(this.element).addClass("d-flex")
  }

  hide() {
    $(this.element).addClass("d-none")
    $(this.element).removeClass("d-flex")
  }
}
