import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "submit"]
  static values = { clearInputDefaultEvent: Boolean }

  connect() {
    if (this.hasSubmitTarget) {
      this.updateSubmit()
    }
  }

  updateSubmit() {
    if (this.allInputsEmpty()) {
      this.submitTarget.disabled = true
    } else {
      this.submitTarget.disabled = false
    }
  }

  clear(event) {
    if (this.hasClearInputDefaultEvent && this.clearInputDefaultEvent.value) { event.preventDefault(); }

    let clearableInputs = this.inputTargets

    if (this.data.has("relatedSelector")) {
      clearableInputs = clearableInputs.concat(
        Array.from(document.querySelectorAll(this.data.get("relatedSelector")))
      )
    }

    clearableInputs.forEach((input) => {
      switch (input.type) {
        case "checkbox":
        case "radio":
          input.checked = input.dataset.clearValue || false
          break
        case "select-one":
          input.selectIndex = input.dataset.clearValue || 0
        default:
          input.value = input.dataset.clearValue || ""
      }
    })
  }

  // Private

  allInputsEmpty() {
    return this.inputTargets.map((input) => {
      if (input.type === "checkbox" || input.type === "radio") {
        return !input.checked
      }
      else {
        return input.value === ""
      }
    }).every(value => value === true)
  }
}
